import React from "react";

const InfoDisplay = ({title, text}) => {
  return (
    <div className="bg-white rounded-xl shadow-lg p-4">
      <p className="text-xs text-[#BEB9B9]">{title}</p>
      <p className="text-[#565656] font-semibold">
        {text}
      </p>
    </div>
  );
};

export default InfoDisplay;
