import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { BiSearch, BiArrowBack } from "react-icons/bi";

import Notification from "../notification/notification/notification";
import { useContext } from "react";
import { GlobalState } from "../../data/Context";
import Profilerounded from "../profile-rounded/profile-rounded";

const DefaultHeader = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { auth } = useContext(GlobalState);
  return (
    <div className="mb-8">
      <div className="flex items-center justify-between mb-4">
        <form className="w-3/5">
          <label
            for="default-search"
            className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white"
          >
            Search
          </label>
          <div className="relative">
            <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
              <svg
                className="w-4 h-4 text-gray-500 dark:text-gray-400"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 20"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                />
              </svg>
            </div>
            <input
              type="search"
              id="default-search"
              className="block w-full px-4 h-10 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500"
              placeholder="Search Mockups, Logos..."
              required
            />
          </div>
        </form>
        <div className="profile flex items-center gap-4">
          <span>
            <div className="flex items-center space-x-4">
              <div class="relative inline-flex items-center justify-center w-10 h-10 overflow-hidden border-red-600 border bg-gray-100 rounded-full dark:bg-gray-600">
                <Profilerounded sm img={auth?.user?.avatar?.url} />
              </div>
              <div className="font-medium">
                <div>Ikujuni Caroline</div>
                <div className="text-sm text-gray-5">Admin Account</div>
              </div>
            </div>
          </span>
          <span>
            <Notification />
          </span>
        </div>
      </div>
      <p className="flex gap-2 capitalize">
        <div
          className="p-1 bg-gray-400 text-white w-fit cursor-pointer"
          onClick={() => navigate(-1)}
        >
          <BiArrowBack />
        </div>
        {location.pathname.split("/")[1]}
      </p>
    </div>
  );
};

export default DefaultHeader;
