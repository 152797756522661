import React, { useEffect, useState } from "react";
import InfoCard from "../../components/info-card/info-card.component";
import Button from "../../components/button/button";
import Pagination from "../../components/pagination/pagination";
import { MdEdit } from "react-icons/md";
import { RiDeleteBin6Line } from "react-icons/ri";
import { Link, useNavigate } from "react-router-dom";
import ModalContainer, {
  SuccessModal,
} from "../../components/modal-container/modal-container";
import Input from "../../components/input/input";
import axios from "axios";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { setMember, setMembers } from "../../data/Reducers/memberSlice";
import { useSelector } from "react-redux";

const defaultData = {
  firstName: "",
  lastName: "",
  middleNames: "",
  memberId: "",
  familyGroup: "",
};

const options = [
  { name: "Royal Priesthood", value: "royal priesthood" },
  { name: "Engravers", value: "engravers" },
  { name: "Sword Bearers", value: "sword bearers" },
  { name: "Truth Ambassadors", value: "truth ambassadors" },
];
const Members = () => {
  const { members } = useSelector((state) => state.members);
  console.log(members);
  // console.log(member);

  const [memberDetails, setMemberDetails] = useState(defaultData);
  const [isOpen, setIsOpen] = useState(false),
    [success, setSuccess] = useState(false),
    [deleteModal, setDeleteModal] = useState(false),
    [isOpenConfirm, setIsOpenConfirm] = useState(false),
    [mode, setMode] = useState(""),
    [filteredItem, setFiltereditem] = useState(""),
    navigate = useNavigate();
  const dispatch = useDispatch();
  const [currentItem, setCurrentItem] = useState("");
  const toggleIsOpen = (modeType, id) => {
    setMode(modeType);
    if (id) {
      setCurrentItem(id);
      const member = members.find((member) => member._id === id);
      console.log({ member });
      setMemberDetails({
        firstName: member.firstName,
        lastName: member.lastName,
        middleNames: member.middleNames,
        memberId: member.memberId,
        familyGroup: member.familyGroup,
      });
    }
    setIsOpen(!isOpen);
    // setPressed(true);
  };
  const toggleDelete = (id) => {
    setDeleteModal(!deleteModal);
    setCurrentItem(id);
  };

  const closeModal = () => {
    setMode("");
    setCurrentItem("");
    setIsOpen(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setMemberDetails({
      ...memberDetails,
      [name]: value,
    });
  };

  const toggleSuccess = () => {
    setSuccess(!success);
  };

  const createNewMember = async (e) => {
    e.preventDefault();
    console.log(mode);
    console.log({ ...memberDetails, memberId: Number(memberDetails.memberId) });

    try {
      const res = await axios.post("/members", {
        ...memberDetails,
        memberId: Number(memberDetails.memberId),
      });
      console.log(res);
      toggleSuccess();
      toggleIsOpen();
    } catch (err) {
      console.log({ err });
      toast.error(err?.response?.data ? err?.response?.data : err?.message);
    }
  };

  const editMember = async (e) => {
    e.preventDefault();
    console.log(mode, currentItem);
    console.log({
      ...memberDetails,
      memberId: Number(memberDetails?.memberId),
    });

    try {
      const res = await axios.post(`/members/${currentItem}`, {
        ...memberDetails,
        memberId: Number(memberDetails?.memberId),
      });
      console.log(res);
      toggleSuccess();
      toggleIsOpen();
    } catch (err) {
      console.log({ err });
      toast.error(err?.response?.data ? err?.response?.data : err?.message);
    }
  };

  const deleteMember = async (e) => {
    e.preventDefault();
    console.log({ ...memberDetails, memberId: Number(memberDetails.memberId) });

    try {
      const res = await axios.delete(`/members/${currentItem}`);
      console.log(res);
      toggleDelete();
      toggleSuccess();
    } catch (err) {
      console.log({ err });
      toast.error(err?.response?.data ? err?.response?.data : err?.message);
    }
  };

  const filterTable = (e) => {
    setFiltereditem(e.target.value);
    console.log(filteredItem);
  };

  useEffect(() => {
    const setMembersData = async () => {
      try {
        const res = await axios.get(`/members`);
        console.log({ res });
        dispatch(setMembers(res));
      } catch (err) {
        console.log({ err });
      }
    };
    setMembersData();
  }, [success]);

  useEffect(() => {
    const setMemberData = async () => {
      try {
        const res = await axios.get(`/members/${currentItem}`);
        console.log({ res });
        dispatch(setMember(res.data));
      } catch (err) {
        console.log({ err });
      }
    };
    setMemberData();
  }, [currentItem]);

  return (
    <div>
      <div className="flex items-center justify-between">
        <div className="w-1/5">
          {/* <InfoCard count={"250"} title={"Members"} /> */}
        </div>
        <Button
          buttonType={"primary"}
          title={"Add New Member"}
          style={"h-10 w-fit"}
          onClick={() => toggleIsOpen("add new")}
        />
      </div>

      <div className="flex items-center justify-between gap-2 my-4">
        <div>
          <p className="font-bold text-2xl">All Members</p>
          <p className="text-sm font-extralight mt-2">
            Monitor Church Attendance
          </p>
        </div>
        <div></div>
      </div>

      {/* Members Table */}
      <div className="relative overflow-x-auto shadow-md sm:rounded-lg mt-8">
        <div className="bg-white dark:bg-gray-900 m-4">
          <label for="table-search" className="sr-only">
            Search
          </label>
          <div className="relative mt-1">
            <div className="absolute inset-y-0 rtl:inset-r-0 start-0 flex items-center ps-3 pointer-events-none">
              <svg
                className="w-4 h-4 text-gray-500 dark:text-gray-400"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 20"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                />
              </svg>
            </div>
            <input
              type="text"
              id="table-search"
              className="block pt-2 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg w-80 bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Search for items"
              onChange={filterTable}
            />
          </div>
        </div>
        <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 capitalize bg-gray-50 whitespace-nowrap">
            <tr>
              <th scope="col" className="px-6">
                s/n
              </th>
              <th scope="col" className="px-6 py-3">
                Name
              </th>
              <th scope="col" className="px-6 py-3">
                Member Id
              </th>
              <th scope="col" className="px-6 py-3">
                Tribe
              </th>
              <th scope="col" className="px-6 py-3">
                Sex
              </th>
              <th scope="col" className="px-6 py-3">
                Level
              </th>
              <th scope="col" className="px-6 py-3">
                Discipline
              </th>
              <th scope="col" className="px-6 py-3">
                Email address
              </th>
              <th scope="col" className="px-6 py-3">
                <span class="sr-only">Edit</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {members.length <= 0 && (
              <div className="flex item-center justify-between h-40">
                <p className="text-center text-2xl p-2">No registered member</p>
              </div>
            )}
            {members
              ?.filter((member) =>
                (member.lastName + " " + member.firstName).includes(
                  filteredItem
                )
              )
              .map((member, idx) => (
                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                  <td class="w-4 px-6">{idx + 1}</td>
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap hover:underline"
                  >
                    <Link to="/members/Ikujuni-caroline">{`${member.lastName} ${member.firstName}`}</Link>
                  </th>
                  <td className="px-6 py-4">{member?.memberId}</td>
                  <td className="px-6 py-4 capitalize">
                    {member?.familyGroup}
                  </td>
                  <td className="px-6 py-4">-</td>
                  <td className="px-6 py-4">-</td>
                  <td className="px-6 py-4">-</td>
                  <td className="px-6 py-4">-</td>
                  <td className="px-6 py-4 text-right">
                    <div className="flex items-center gap-4">
                      <div
                        className="flex items-center justify-center h-5 w-5 rounded-sm cursor-pointer text-white bg-main"
                        onClick={() => toggleIsOpen("edit", member._id)}
                      >
                        <MdEdit />
                      </div>
                      <div
                        className="flex items-center justify-center h-5 w-5 rounded-sm cursor-pointer text-white bg-main"
                        onClick={() => toggleDelete(member._id)}
                      >
                        <RiDeleteBin6Line />
                      </div>
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      <div className="mt-4">
        <Pagination />
      </div>

      {/* Modal */}
      <ModalContainer
        close={toggleIsOpen}
        show={isOpen}
        title={mode + " member"}
        width={"max-w-4xl"}
      >
        <form onSubmit={mode === "edit" ? editMember : createNewMember}>
          <div className="grid grid-cols-2 gap-4">
            <Input
              type={"text"}
              label={"Member Id"}
              name={"memberId"}
              value={memberDetails?.memberId}
              onChange={handleChange}
            />
            <Input
              type={"text"}
              label={"First Name"}
              name={"firstName"}
              value={memberDetails?.firstName}
              onChange={handleChange}
            />
            <Input
              type={"text"}
              label={"Last Name"}
              name={"lastName"}
              value={memberDetails?.lastName}
              onChange={handleChange}
            />
            <Input
              type={"text"}
              label={"Middle Names"}
              name={"middleNames"}
              value={memberDetails?.middleNames}
              onChange={handleChange}
            />
            <Input
              type={"select"}
              name={"familyGroup"}
              value={memberDetails?.familyGroup}
              onChange={handleChange}
              options={options}
            />
          </div>
          <div className="flex items-center gap-8">
            <Button
              buttonType={"tetiary"}
              title={"Cancel"}
              style={"w-fit"}
              onClick={() => {
                toggleIsOpen();
                setMemberDetails(defaultData);
              }}
            />
            <Button
              type={"submit"}
              buttonType={"primary"}
              title={mode === "edit" ? "Edit" : "Create"}
              style={"w-fit"}
            />
          </div>
        </form>
      </ModalContainer>
      <SuccessModal show={success} close={toggleSuccess}>
        <div>
          <p className="text-4xl text-center text-green-500">Success</p>
          <div className="flex justify-center mt-4">
            <Button
              buttonType={"primary"}
              title={"Continue"}
              style={"w-fit"}
              onClick={toggleSuccess}
            />
          </div>
        </div>
      </SuccessModal>
      <ModalContainer
        close={toggleDelete}
        show={deleteModal}
        title={"Delete member"}
        width={"max-w-2xl"}
      >
        <div>
          <img
            src={require("../../assets/delete-gif.gif")}
            alt=""
            className="mx-auto h-40"
          />
          <p className="text-center text-md">
            Are you sure you want to delete member? <br />
            Action cannot be undone
          </p>
          <div className="flex justify-center gap-x-8 mt-4">
            <Button
              buttonType={"tetiary"}
              css={"w-fit"}
              title={"No"}
              onClick={toggleDelete}
              //   loading={loading}
              //   loadCss="#000"
            />
            <Button
              buttonType={"primary"}
              title={"Yes"}
              css={"w-fit"}
              onClick={deleteMember}
            />
          </div>
        </div>
      </ModalContainer>
    </div>
  );
};

export default Members;
