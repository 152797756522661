import React from "react";
import pattern from "../../assets/pattern/info-card-pattern.png";

function InfoCard({ title, count, color, handleClick }) {
  return (
    <div
      className={`py-4 px-2 rounded-md ${color} cursor-pointer relative overflow-hidden`}
      style={{
        background: `url(${pattern}) no-repeat`,
        backgroundSize: "cover",
      }}
      onClick={handleClick}
    >
      <div className="absolute inset-0 bg-black opacity-30"></div>
      <div className="relative z-20 text-white">
        <p className="text-xl font-bold">{count}</p>
        <p className="text-md font-light">{title}</p>
      </div>
    </div>
  );
}

export default InfoCard;
