import { combineReducers } from "redux";
import UserReducer from "./UserReducer";
import memberSlice from "./memberSlice";

import { errorSlice } from "./ErrorReducer";

const rootReducer = combineReducers({
	auth: UserReducer,
	members: memberSlice,
	error: errorSlice.reducer,
});

export default rootReducer;
