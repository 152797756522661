import React from "react";
import InfoCard from "../../components/info-card/info-card.component";
import Button from "../../components/button/button";
import Pagination from "../../components/pagination/pagination";
import { MdEdit } from "react-icons/md";
import { RiDeleteBin6Line } from "react-icons/ri";
import { Link } from "react-router-dom";

const FirstTimers = () => {
  return (
    <div>
      <div className="flex items-center justify-between">
        <div className="w-1/5">
          {/* <InfoCard count={"250"} title={"Members"} /> */}
        </div>
        <Button
          buttonType={"primary"}
          title={"Add New First Timer"}
          style={"h-10 w-fit"}
        />
      </div>

      <div className="flex items-center justify-between gap-2 my-4">
        <div>
          <p className="font-bold text-2xl">All First Timers</p>
          <p className="text-sm font-extralight mt-2">
            Monitor Church Attendance
          </p>
        </div>
        <div></div>
      </div>

      {/* Members Table */}
      <div className="relative overflow-x-auto shadow-md sm:rounded-lg mt-8">
        <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 capitalize bg-gray-50 whitespace-nowrap">
            <tr>
              <th scope="col" className="px-6">
                s/n
              </th>
              <th scope="col" className="px-6 py-3">
                Name
              </th>
              <th scope="col" className="px-6 py-3">
                Status
              </th>
              <th scope="col" className="px-6 py-3">
                Date of birth
              </th>
              <th scope="col" className="px-6 py-3">
                Sex
              </th>
              <th scope="col" className="px-6 py-3">
                Level
              </th>
              <th scope="col" className="px-6 py-3">
                Discipline
              </th>
              <th scope="col" className="px-6 py-3">
                Email address
              </th>
              <th scope="col" className="px-6 py-3">
                <span class="sr-only">Edit</span>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
              <td class="w-4 px-6">1</td>
              <th
                scope="row"
                className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
              >
                Iujuni Caroline
              </th>
              <td className="px-6 py-4">Worker</td>
              <td className="px-6 py-4">12th December</td>
              <td className="px-6 py-4">Female</td>
              <td className="px-6 py-4">400 Level</td>
              <td className="px-6 py-4">SLT</td>
              <td className="px-6 py-4">ikujunicaroline@gmail.com</td>
              <td className="px-6 py-4 text-right">
                <div className="flex items-center gap-4">
                  <div className="flex items-center justify-center h-5 w-5 rounded-sm cursor-pointer text-white bg-main">
                    <MdEdit />
                  </div>
                  <div className="flex items-center justify-center h-5 w-5 rounded-sm cursor-pointer text-white bg-main">
                    <RiDeleteBin6Line />
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="mt-4">
        <Pagination />
      </div>
    </div>
  );
};

export default FirstTimers;
