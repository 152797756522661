import { createSlice } from "@reduxjs/toolkit";
// import { clearErrors, getErrorText } from "./ErrorReducer";
import axios from "axios";
// import { toast } from "react-toastify";

export const TOKEN = "POPUP_LOGIN";

let initialState = {
  members: [],
  member: {}
};

export const memberSlice = createSlice({
  name: "member",
  initialState,
  reducers: {
    setMembers: (state, { payload }) => {
      state.members = payload?.data;
    },
    setMember: (state, {payload}) => {
      state.member = payload?.data
    }
  },
});

// Action creators are generated for each case reducer function
export const { setMembers, getMembers, setMember } = memberSlice.actions;

export default memberSlice.reducer;

export const addNewMember = async (memberDetails) => {
  try {
    const res = await axios.post("/members", memberDetails);
  } catch (err) {
    console.log({ err });
  }
};
