import React from "react";
import Profilerounded from "../../components/profile-rounded/profile-rounded";

import { MdUpload } from "react-icons/md";
import Button from "../../components/button/button";
import InfoDisplay from "../../components/info-display/info-display";
import { useSelector } from "react-redux";

const Profile = () => {
  const { members } = useSelector((state) => state.members);
  console.log(members);
  return (
    <div>
      <div>
        <p className="font-bold text-2xl">Profile</p>
      </div>
      <div className="grid md:grid-cols-2 mt-4 gap-8">
        <div>
          <div className="relative flex justify-center">
            <img src={require("../../assets/pp-bg.png")} alt="" />
            <div className="absolute -bottom-7 w-fit rounded-full border-2 border-gray-200 p-1 bg-white">
              <Profilerounded lg />
            </div>
          </div>
          <p className="mt-8 font-bold text-3xl text-center">
            Ikujuni Caroline Deborah
          </p>
        </div>
        <div className="flex gap-8 mx-auto">
          <div className="w-52 h-52 border-2 bg-tetiary gap-y-4 border-gray-200 flex items-center justify-center flex-col rounded-xl border-dashed text-main">
            <span className="text-main">
              <MdUpload size={60} />
            </span>
            <p className="capitaize text-lg font-medium">upload a pic</p>
            <p className="text-xs opacity-30 text-center">
              PNG, and PG files are allowed
            </p>
          </div>
          <div className="c self-center">
            <p className="font-semibold mb-4 text-center text-2xl">Upload picture</p>
            <div className="flex justify-center">
              <Button title={"Upload Now"} buttonType={"primary"} style={"w-fit"} />
            </div>
          </div>
        </div>
      </div>
      <div className="my-16">
        <p className="font-semibold text-center text-2xl mb-8">General information</p>

        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-4 mt-4">
             <InfoDisplay title={"Education"} text={"Ladoke Akintola University of Technology"} />
             <InfoDisplay title={"Education"} text={"Ladoke Akintola University of Technology"} />
             <InfoDisplay title={"Education"} text={"Ladoke Akintola University of Technology"} />
             <InfoDisplay title={"Education"} text={"Ladoke Akintola University of Technology"} />
             <InfoDisplay title={"Education"} text={"Ladoke Akintola University of Technology"} />
             <InfoDisplay title={"Education"} text={"Ladoke Akintola University of Technology"} />
             <InfoDisplay title={"Education"} text={"Ladoke Akintola University of Technology"} />
             <InfoDisplay title={"Education"} text={"Ladoke Akintola University of Technology"} />
             <InfoDisplay title={"Education"} text={"Ladoke Akintola University of Technology"} />
        </div>
      </div>
    </div>
  );
};

export default Profile;
